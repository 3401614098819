import { memo, useEffect, useMemo, useState } from "react";
import {
    PrismicDocument,
    createClient,
    getRepositoryEndpoint,
} from "@prismicio/client";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { SocialIcon } from "components/Icons/Social";
import { LogomarkWithNameBlue } from "assets/vectors/logo";
import Link from "next/link";

type PrismicData = {
    copyright: RichTextBlock[];
    social: {
        name: string;
        path: RichTextBlock[];
    }[];
    body: {
        primary: {
            title: RichTextBlock[];
        };
        items: {
            name: RichTextBlock[];
            path: RichTextBlock[];
        }[];
    }[];
};

export const DesktopFooter = memo(() => {
    const [documentFooter, setDocumentFooter] = useState<PrismicDocument>();
    const getText = (richText: RichTextBlock[]): string =>
        RichText.asText(richText);

    useEffect(() => {
        if (!documentFooter)
            getPrismicFooter().then((res) => setDocumentFooter(res));
    }, [documentFooter]);

    const footer = useMemo(() => {
        if (!documentFooter) {
            return {
                copyright: "",
                sections: [],
                social: [],
            };
        }
        const data = documentFooter.data as PrismicData;

        const links = data.body.map((b) => ({
            title: getText(b.primary.title),
            items: b.items.map((i) => ({
                name: getText(i.name),
                path: getText(i.path),
            })),
        }));

        const social = data.social.map((s) => ({
            icon: (
                <SocialIcon
                    type={s.name}
                    props={{ className: "text-primary size-6" }}
                />
            ),
            path: getText(s.path),
        }));

        return {
            copyright: getText(data.copyright),
            links: links,
            social: social,
        };
    }, [documentFooter]);

    return (
        <footer className="hidden flex-col items-center gap-16 divide-x-0 divide-y divide-solid divide-blue-grey-50 bg-white py-16 lg:flex">
            <div className="flex w-full max-w-[1056px] justify-between">
                <LogomarkWithNameBlue />
                {footer.links?.map((l) => (
                    <div
                        key={l.title}
                        className="typography-sub flex flex-col gap-4"
                    >
                        <div className="font-bold text-blue-grey-900">
                            {l.title}
                        </div>
                        <div className="flex flex-col gap-3">
                            {l.items.map((i) => (
                                <Link key={i.name} href={i.path}>
                                    <div className="cursor-pointer text-blue-grey-300">
                                        {i.name}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex w-full max-w-[1056px] justify-between pt-6">
                <div className="typography-sub text-blue-grey-400">
                    {footer.copyright}
                </div>
                <div className="flex gap-2">
                    {footer.social.map((s) => (
                        <Link key={s.path} href={s.path}>
                            <a className="cursor-pointer">{s.icon}</a>
                        </Link>
                    ))}
                </div>
            </div>
        </footer>
    );
});
DesktopFooter.displayName = "Footer";

export const getPrismicFooter = async (): Promise<PrismicDocument> => {
    const endpoint = getRepositoryEndpoint("courtsite");
    const client = createClient(endpoint);

    return await await client.getByID("Znk5_hEAACQAMDmF", {});
};
